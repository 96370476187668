
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import {
  FilterTagType,
  getFilterParamAsString,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
  useDataTable,
  useSelectOptionsResource,
  EyeIcon,
  DataTable,
  BaseButton,
  AdvancedSearch,
  FormField,
  FormFieldMultiSelect,
} from "@tager/admin-ui";
import { FETCH_STATUSES, notEmpty } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { getOrderViewUrl } from "@/utils/paths";
import { dateFormat } from "@/utils/common";
import { COLUMN_DEFS } from "@/modules/orders/OrdersList/OrdersList.helpers";

import { Label, OrderType } from "../typings";
import {
  getOrderList,
  getOrderTypeLabelList,
  getOrderStatusLabelList,
} from "../requests";

function generateBillingLink(paymentId: string) {
  const link = "https://billing-service.dev.atlantm.by/payments?query=";
  return link + paymentId;
}

export default defineComponent({
  name: "OrderList",
  components: {
    Page,
    EyeIcon,
    BaseButton,
    AdvancedSearch,
    FormField,
    FormFieldMultiSelect,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      loading: isStatusListLoading,
      status: statusListRequestStatus,
      options: statusOptionList,
      fetchEntityList: fetchStatusList,
    } = useSelectOptionsResource<Label, OptionType>({
      fetchEntityList: getOrderStatusLabelList,
      resourceName: "Список статусов заказов",
      minQueryLength: 0,
      convertEntityToOption: (status) => {
        return {
          value: status.name,
          label: status.label,
        };
      },
    });

    const {
      loading: isTypeListLoading,
      status: typeListRequestStatus,
      options: typeOptionList,
      fetchEntityList: fetchTypeList,
    } = useSelectOptionsResource<Label, OptionType>({
      fetchEntityList: getOrderTypeLabelList,
      resourceName: "Список типов заказов",
      minQueryLength: 0,
      convertEntityToOption: (type) => {
        return {
          value: type.name,
          label: type.label,
        };
      },
    });

    onMounted(() => {
      fetchStatusList({ query: "" });
      fetchTypeList({ query: "" });
    });

    const initialStatusFilter = computed<Array<OptionType>>(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "status");

      return statusOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const statusFilter = ref<Array<OptionType>>(initialStatusFilter.value);

    watch(initialStatusFilter, () => {
      statusFilter.value = initialStatusFilter.value;
    });

    const initialTypeFilter = computed<Array<OptionType>>(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "type");

      return typeOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const typeFilter = ref<Array<OptionType>>(initialTypeFilter.value);

    watch(initialTypeFilter, () => {
      typeFilter.value = initialTypeFilter.value;
    });

    const initialFromDateFilter = computed<string>(
      () => getFilterParamAsString(route.query, "fromDate") ?? ""
    );

    const fromDateFilter = ref<string>(initialFromDateFilter.value);

    watch(initialFromDateFilter, () => {
      fromDateFilter.value = initialFromDateFilter.value;
    });

    const initialToDateFilter = computed<string>(
      () => getFilterParamAsString(route.query, "toDate") ?? ""
    );

    const toDateFilter = ref<string>(initialToDateFilter.value);

    watch(initialToDateFilter, () => {
      toDateFilter.value = initialToDateFilter.value;
    });

    const date = computed<FilterTagType | null>(() => {
      if (!fromDateFilter.value && !toDateFilter.value) {
        return null;
      }

      let label = "";

      if (fromDateFilter.value && toDateFilter.value) {
        label = `${dateFormat(fromDateFilter.value)} - ${dateFormat(
          toDateFilter.value
        )}`;
      }

      if (fromDateFilter.value && !toDateFilter.value) {
        label = `От ${dateFormat(fromDateFilter.value)}`;
      }

      if (!fromDateFilter.value && toDateFilter.value) {
        label = `До ${dateFormat(toDateFilter.value)}`;
      }

      return {
        value: "",
        label,
        name: "fromDate",
        title: "Дата",
      };
    });

    const filterParams = computed(() => {
      return getFilterParams({
        status: statusFilter.value.map((tag) => tag.value),
        type: typeFilter.value.map((tag) => tag.value),
        fromDate: fromDateFilter.value,
        toDate: toDateFilter.value,
      });
    });

    const {
      isLoading: isOrderListDataLoading,
      rowData: orderListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchOrderList,
    } = useDataTable<OrderType>({
      fetchEntityList: (params) =>
        getOrderList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список платежей",
    });

    const isAllFilterDataLoaded = computed(() => {
      return [statusListRequestStatus.value, typeListRequestStatus.value].every(
        (status) => status === FETCH_STATUSES.SUCCESS
      );
    });

    watch(filterParams, () => {
      if (!isAllFilterDataLoaded.value) return;

      const newQuery = {
        ...filterParams.value,
        query: route.query.query,
      };

      if (!_.isEqual(route.query, newQuery)) {
        router.replace({
          query: newQuery,
        });
        fetchOrderList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "status") {
        statusFilter.value = statusFilter.value.filter(
          (tag) => String(tag.value) !== event.value
        );
      }
      if (event.name === "type") {
        typeFilter.value = typeFilter.value.filter(
          (tag) => String(tag.value) !== event.value
        );
      }
      if (event.name === "fromDate") {
        fromDateFilter.value = "";
        toDateFilter.value = "";
      }
    }

    const tags = computed<Array<FilterTagType>>(() =>
      [
        ...statusFilter.value.map((tag) => ({
          value: String(tag.value),
          label: tag.label,
          name: "status",
          title: "Статусы",
        })),
        ...typeFilter.value.map((tag) => ({
          value: String(tag.value),
          label: tag.label,
          name: "type",
          title: "Типы заказов",
        })),
        date.value,
      ].filter(notEmpty)
    );

    function isBusy(): boolean {
      return (
        isOrderListDataLoading.value ||
        isStatusListLoading.value ||
        isTypeListLoading.value
      );
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: orderListData,
      isRowDataLoading: isOrderListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getOrderViewUrl,
      isBusy,
      statusOptionList,
      statusFilter,
      fromDateFilter,
      toDateFilter,
      typeOptionList,
      typeFilter,
      handleTagRemove,
      tags,
      generateBillingLink,
    };
  },
});
