import { ColumnDefinition } from "@tager/admin-ui";

import { getOrderViewUrl } from "@/utils/paths";
import { OrderType } from "@/modules/orders/typings";

function generateBillingLink(paymentId: string) {
  const link = "https://billing-service.dev.atlantm.by/payments?query=";
  return link + paymentId;
}

export const COLUMN_DEFS: Array<ColumnDefinition<OrderType>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "52px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "52px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Создан",
    field: "createdAt",
    type: "datetime",
    style: { width: "120px", whiteSpace: "nowrap" },
    headStyle: { width: "120px" },
  },
  {
    id: 3,
    name: "Статус",
    field: "status",
    style: { width: "150px", whiteSpace: "nowrap" },
    headStyle: { width: "150px" },
    class: ({ row }) => {
      if (row.status === "Завершён") return "orders-list-text-success";
      if (row.status === "Отменён") return "orders-list-text-danger";
    },
  },
  {
    id: 4,
    name: "Тип",
    field: "type",
  },
  {
    id: 5,
    name: "Покупатель",
    field: "customer",
    type: "key-value",
    format: ({ row }) => {
      return [
        { key: "Имя", value: row.name || "" },
        { key: "Email", value: row.email || "" },
      ];
    },
  },
  {
    id: 6,
    name: "Сумма",
    field: "total",
    format: ({ row }) => row.total + " BYN",
    style: { width: "100px", whiteSpace: "nowrap" },
    headStyle: { width: "100px" },
  },
  {
    id: 7,
    name: "Платеж",
    field: "payment",
    style: { width: "100px", whiteSpace: "nowrap" },
    headStyle: { width: "100px" },
    type: "link",
    format: ({ row }) => {
      return row.paymentId
        ? {
            url: generateBillingLink(row.paymentId),
            text: row.paymentNumber || "",
          }
        : null;
    },
    options: {
      disableCopyButton: true,
    },
  },
  {
    id: 8,
    name: "",
    field: "actions",
    style: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "50px", textAlign: "center" },
  },
];
