export const dateFormat = (date: string) => date.split('-').reverse().join('.');

export function formatNumber(number: number): string {
  const formattedNumber: string = number.toString();

  /** Reference: https://stackoverflow.com/a/16637170 */
  const regexp = /\B(?=(\d{3})+(?!\d))/g;

  return formattedNumber ? formattedNumber.replace(regexp, ' ') : '';
}
