import { ColumnDefinition } from "@tager/admin-ui";

import { HelpOnRoadOrderType } from "@/modules/orders/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<HelpOnRoadOrderType>> = [
  {
    id: 1,
    name: "Заказ",
    field: "orderId",
    style: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "80px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Создан",
    field: "createdAt",
    type: "datetime",
    style: { width: "120px", whiteSpace: "nowrap" },
    headStyle: { width: "120px" },
  },
  {
    id: 3,
    name: "Статус",
    field: "status",
    style: { width: "150px", whiteSpace: "nowrap" },
    headStyle: { width: "150px" },
    class: ({ row }) => {
      if (row.status === "PAID") return "orders-list-text-success";
      if (row.status === "WAITING_PAYMENT") return "orders-list-text-danger";
    },
    format: ({ row }) => {
      if (row.status === "PAID") return "Оплачен";
      if (row.status === "WAITING_PAYMENT") return "Ожидает оплаты";
    },
  },
  {
    id: 4,
    name: "Клиент",
    field: "user",
    type: "key-value",
    format: ({ row }) => {
      return [
        { key: "Имя", value: row.name || "" },
        { key: "Email", value: row.email || "" },
        { key: "Телефон", value: row.phone || "" },
      ];
    },
  },
  {
    id: 5,
    name: "Заказ",
    field: "order",
    type: "key-value",
    format: ({ row }) => {
      return [
        { key: "Тарифный план", value: row.plan || "" },
        { key: "Бренд", value: row.brand || "" },
        { key: "Модель", value: row.model || "" },
        { key: "VIN", value: row.vin || "" },
      ];
    },
  },
  {
    id: 6,
    name: "Авто-Асситанс",
    field: "autoAssistanceFile",
  },
];
