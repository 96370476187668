import { request, ResponseBody } from "@tager/admin-services";

import {
  FullOrderType,
  HelpOnRoadOrderType,
  Label,
  OrderType,
} from "./typings";

export interface SuccessData {
  success: boolean;
}

export interface CountData {
  count: number;
}

export function getOrderCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/orders/count" });
}

export function getOrderHelpOnRoadCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/orders/help-on-road/count" });
}

export function getOrderList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<OrderType>>> {
  return request.get({ path: "/admin/orders", params });
}

export function getOrderHelpOnRoadList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<HelpOnRoadOrderType>>> {
  return request.get({ path: "/admin/orders/help-on-road", params });
}

export function getOrder(
  orderId: string | number
): Promise<ResponseBody<FullOrderType>> {
  return request.get({ path: `/admin/orders/${orderId}` });
}

export function getOrderStatusLabelList(): Promise<ResponseBody<Array<Label>>> {
  return request.get({ path: "/admin/orders/status-list" });
}

export function getOrderTypeLabelList(): Promise<ResponseBody<Array<Label>>> {
  return request.get({ path: "/admin/orders/type-list" });
}
