import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import { ROUTE_PATHS } from "@/constants/paths";

import HelpOnRoadList from "./HelpOnRoadList";
import OrdersList from "./OrdersList";
import OrdersView from "./OrdersView";

export const ORDERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDER_LIST,
  component: OrdersList,
  name: "Заказы",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().ORDER_LIST],
  },
};

export const ORDERS_HELP_ON_ROAD_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDER_HELP_ON_ROAD_LIST,
  component: HelpOnRoadList,
  name: "Заказы - Помощь на дороге",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().ORDER_HELP_ON_ROAD_LIST],
  },
};

export const ORDERS_VIEW_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDER_VIEW,
  component: OrdersView,
  name: "Просмотр заказа",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().ORDER_LIST,
      {
        url: route.path,
        text: "Просмотр заказа",
      },
    ],
  },
};
