import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getOrderListUrl() {
  return ROUTE_PATHS.ORDER_LIST;
}

export function getOrderHelpOnRoadListUrl() {
  return ROUTE_PATHS.ORDER_HELP_ON_ROAD_LIST;
}

export function getOrderViewUrl(params: { orderId: string | number }) {
  return compile(ROUTE_PATHS.ORDER_VIEW)(params);
}
