import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getSettingItemListUrl } from "@tager/admin-settings";

import { getOrderHelpOnRoadListUrl, getOrderListUrl } from "@/utils/paths";

type LinkKey =
  | "HOME"
  | "ORDER_LIST"
  | "ORDER_HELP_ON_ROAD_LIST"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "SETTINGS_LIST";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  ORDER_LIST: {
    url: getOrderListUrl(),
    text: "Заказы",
  },
  ORDER_HELP_ON_ROAD_LIST: {
    url: getOrderHelpOnRoadListUrl(),
    text: "Помощь на дороге",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
  SETTINGS_LIST: {
    url: getSettingItemListUrl(),
    text: "Настройки",
  },
});
