
import { computed, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";

import { Nullable, useResource } from "@tager/admin-services";
import { formatDateTime, FieldValue } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getOrderListUrl } from "@/utils/paths";
import { formatNumber } from "@/utils/common";

import { FullOrderType } from "../typings";
import { getOrder } from "../requests";

export default defineComponent({
  name: "OrderView",
  components: { Page, FieldValue },
  setup() {
    const route = useRoute();

    const orderId = computed<string>(() => route.params.orderId as string);

    const [fetchOrderForm, { data: order, loading: isOrderLoading }] =
      useResource<Nullable<FullOrderType>>({
        fetchResource: () => getOrder(orderId.value),
        initialValue: null,
        resourceName: "Заказ",
      });

    onMounted(() => {
      fetchOrderForm();
    });

    const isContentLoading = computed<boolean>(() => isOrderLoading.value);

    const pageTitle = computed<string>(() => {
      return order.value?.id ? `Заказ № ${order.value?.id}` : "Заказ";
    });

    const paymentLink = computed<Nullable<string>>(() => {
      const link = "https://billing-service.dev.atlantm.by/payments?query=";

      return order.value ? link + order.value?.paymentId : null;
    });

    const currency = "BYN";

    function generateCarLink(carId: number, carType: string) {
      const link = "https://atlantm.by/cars";
      return `${link}/${carType.toLowerCase()}/${carId}`;
    }

    function generateAddressText(
      city: Nullable<string>,
      street: Nullable<string>,
      house: Nullable<string>,
      housing: Nullable<string>,
      apartment: Nullable<string>,
      zip: Nullable<string>
    ) {
      let resultText = "";
      if (city) {
        resultText += "г. " + city + ", ";
      }
      if (street) {
        resultText += "ул. " + street + ", ";
      }
      if (house) {
        resultText += "д. " + house + ", ";
      }
      if (housing) {
        resultText += "корпус " + housing + ", ";
      }
      if (apartment) {
        resultText += "кв. " + apartment + ", ";
      }
      if (zip) {
        resultText += "индекс " + zip;
      }
      return resultText.trim().replace(/(^,)|(,$)/g, "");
    }

    return {
      backButtonUrl: getOrderListUrl(),
      order,
      isContentLoading,
      formatDateTime,
      formatNumber,
      generateCarLink,
      generateAddressText,
      pageTitle,
      paymentLink,
      currency,
    };
  },
});
