
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGridItem, NavigationGrid } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getOrderCount, getOrderHelpOnRoadCount } from "@/modules/orders";

export default defineComponent({
  name: "HomePage",
  components: {
    NavigationGrid,
  },
  setup() {
    const [
      fetchOrderCount,
      { data: orderCountData, status: orderCountDataStatus },
    ] = useResource({
      fetchResource: getOrderCount,
      resourceName: "Количество заказов",
      initialValue: null,
    });

    const [
      fetchOrderHelpOnRoadCount,
      {
        data: orderHelpOnRoadCountData,
        status: orderHelpOnRoadCountDataStatus,
      },
    ] = useResource({
      fetchResource: getOrderHelpOnRoadCount,
      resourceName: "Количество заказов Помощь на Дороге",
      initialValue: null,
    });

    const links = computed(() => getLinks());

    onMounted(() => {
      fetchOrderCount();
      fetchOrderHelpOnRoadCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.ORDER_LIST.text,
          url: links.value.ORDER_LIST.url,
          total: {
            status: orderCountDataStatus.value,
            value: orderCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.ORDER_HELP_ON_ROAD_LIST.text,
          url: links.value.ORDER_HELP_ON_ROAD_LIST.url,
          total: {
            status: orderHelpOnRoadCountDataStatus.value,
            value: orderHelpOnRoadCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.SETTINGS_LIST.text,
          url: links.value.SETTINGS_LIST.url,
          width: 50,
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
