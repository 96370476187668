
import { computed, defineComponent } from "vue";

import { MenuItemType, PageLayout } from "@tager/admin-layout";
import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "App",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      { id: "orders", icon: ViewListIcon, ...links.value.ORDER_LIST },
      {
        id: "orders_help_on_road",
        icon: ViewListIcon,
        ...links.value.ORDER_HELP_ON_ROAD_LIST,
      },
      { id: "settings", icon: ViewListIcon, ...links.value.SETTINGS_LIST },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
