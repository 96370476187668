import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";

import Home from "@/views/Home.vue";
import { ORDERS_LIST_ROUTE, ORDERS_VIEW_ROUTE } from "@/modules/orders";
import { ORDERS_HELP_ON_ROAD_LIST_ROUTE } from "@/modules/orders/routes";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: () => [{ url: "/", text: "Главная" }],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,

    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    SETTINGS_ITEM_FORM_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,

    ORDERS_LIST_ROUTE,
    ORDERS_HELP_ON_ROAD_LIST_ROUTE,
    ORDERS_VIEW_ROUTE,
  ],
});
