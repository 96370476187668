
import { defineComponent, onMounted } from "vue";

import { useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getOrderViewUrl } from "@/utils/paths";
import { COLUMN_DEFS } from "@/modules/orders/HelpOnRoadList/HelpOnRoadList.helpers";

import { HelpOnRoadOrderType } from "../typings";
import { getOrderHelpOnRoadList } from "../requests";

export default defineComponent({
  name: "OrderList",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      isLoading: isOrderListDataLoading,
      rowData: orderListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchOrderList,
    } = useDataTable<HelpOnRoadOrderType>({
      fetchEntityList: (params) =>
        getOrderHelpOnRoadList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список заказов - Помощь на дороге",
    });

    onMounted(() => {
      fetchOrderList();
    });

    function isBusy(): boolean {
      return isOrderListDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: orderListData,
      isRowDataLoading: isOrderListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getOrderViewUrl,
      isBusy,
    };
  },
});
